import React from "react";
import "./CarouselBrand.css";

const CarouselBrand = () => {
  return (
    <div
      id="carouselExampleDark"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="10000">
          <div className="d-flex justify-content-center">
            <img src="/Asset/Home/Client/Hippo.png" alt="..." />
            <img src="/Asset/Home/Client/Sun Edu.png" alt="..." />
            <img src="/Asset/Home/Client/Ilios.png" alt="..." />
            <img src="/Asset/Home/Client/Melandas.png" alt="..." />
            <img src="/Asset/Home/Client/Dio Living.png" alt="..." />
          </div>
        </div>
        <div className="carousel-item " data-bs-interval="10000">
          <div className="d-flex justify-content-center">
            <img src="/Asset/Home/Client/Nexgen.png" alt="..." />
            <img src="/Asset/Home/Client/Pempek.png" alt="..." />
            <img src="/Asset/Home/Client/Home and Living.png" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselBrand;
