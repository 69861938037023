import React, { useEffect } from "react";

import AboutUsHero from "./AboutUsHero";
import Process from "./Process";
import VisiMisi from "./VisiMisi";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <AboutUsHero />
      <VisiMisi />
      <Process />
    </div>
  );
};

export default AboutUs;
