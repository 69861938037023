import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      mobile: mobile,
      email: email,
      service: service,
      message: message,
    };
    emailjs
      .send("service_p3xxxef", "template_su2uy68", data, "Kub04WK8Jf7vf6SqK")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setName("");
    setMobile("");
    setEmail("");
    setService("");
    setMessage("");
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const pesan = {
  //     name: name,
  //     mobile: mobile,
  //     email: email,
  //     service: service,
  //     message: message,
  //   };
  //   setName("");
  //   setMobile("");
  //   setEmail("");
  //   setService("");
  //   setMessage("");
  //   console.log(pesan);
  // };

  return (
    <section id="contact">
      <div
        className="container text-lg-start text-center"
        style={{ padding: "60px 0" }}
      >
        <div className="row">
          <div className="col-lg-5">
            <h1>Get In Touch</h1>
            <p>
              We're here to help you inline with your business requirements. For
              a quick response, send us your information details and our team
              will contact you shortly.
            </p>
            <h2>Follow Us</h2>
            <a
              href="https://www.facebook.com/PT-Alba-Digital-Teknologi-107617371072128"
              target="_blank"
              rel="noreferrer noopener"
            >
              <span>
                <img src="/Asset/Contact/fb.png" alt="facebook" />
              </span>
            </a>
            <a
              href="https://www.instagram.com/albatech.id/"
              target="_blank"
              rel="noreferrer noopener"
              style={{ marginLeft: "30px" }}
            >
              <span>
                <img src="/Asset/Contact/ig.png" alt="facebook" />
              </span>
            </a>

            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d991.5686718478718!2d106.65741735990441!3d-6.22746940424168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sid!4v1646195978357!5m2!1sen!2sid"
                frameBorder="0"
                style={{ border: "0" }}
                allowFullScreen=""
                title="alba"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-7" style={{ padding: "0 20px" }}>
            <form ref={form} onSubmit={sendEmail}>
              <div className="mb-4 row">
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    id="mobile"
                    name="mobile"
                    placeholder="Mobile"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <select
                  className="form-select"
                  name="service"
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                >
                  <option defaultValue={""}>Chosee Services</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="IT Service">IT Service</option>
                </select>
              </div>
              {/* <div className="mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="service"
                  placeholder="Chosee Services"
                />
              </div> */}
              <div className="mb-4">
                <textarea
                  type="text"
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button className="btn btn-yellow" style={{ width: "100%" }}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
