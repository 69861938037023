import React from "react";
import "./Process.css";

const Process = () => {
  const processData = [
    {
      id: 1,
      title: "Finding Data from Insight and Research",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      imgUrl:
        "/Asset/aboutUs/Ilustrasi/Finding Data from Insight and Research.png",
      status: "active",
    },
    {
      id: 2,
      title: "Define Plan & Strategy",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      imgUrl: "/Asset/aboutUs/Ilustrasi/Define Plan & Strategy.png",
      status: "",
    },
    {
      id: 3,
      title: "Developing Solution",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      imgUrl: "/Asset/aboutUs/Ilustrasi/Developing Solution.png",
      status: "",
    },
    {
      id: 4,
      title: "Evaluate Performance",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      imgUrl: "/Asset/aboutUs/Ilustrasi/Evaluate Performance.png",
      status: "",
    },
  ];

  return (
    <div id="process">
      <div className="container-md">
        <div className="title-section">
          <h1>Our Process</h1>
        </div>

        <div className="caraousel">
          <div
            id="ourProcess"
            className="content carousel"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              {processData.map((data) => {
                return (
                  <div className={`carousel-item ${data.status}`} key={data.id}>
                    <div className="row">
                      <div className="col-md-6 carousel-img">
                        <img src={data.imgUrl} alt="lol" />
                      </div>
                      <div className="col-md-6">
                        <h3>{data.title}</h3>
                        {/* <p>{data.text}</p> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="text-center process-btn">
              <button
                className="btn"
                style={{ transform: "rotate(180deg)" }}
                type="button"
                data-bs-target="#ourProcess"
                data-bs-slide="prev"
              >
                <span aria-hidden="true">
                  <img src="/Asset/aboutUs/Arrow.png" alt="arrow" />
                </span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="btn"
                type="button"
                data-bs-target="#ourProcess"
                data-bs-slide="next"
              >
                <span aria-hidden="true">
                  <img src="/Asset/aboutUs/Arrow.png" alt="arrow" />
                </span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
