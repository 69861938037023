import React from "react";
import "./ServiceCard.css";

const ServiceCard = (props) => {
  return (
    <div className="d-flex flex-row">
      <div>
        <img
          src={props.img}
          alt="asdf"
          className="me-3"
          style={{ width: "35px" }}
        />
      </div>
      <div>
        <h4>{props.title}</h4>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
