import React from "react";

const PortofolioImg = (props) => {
  return (
    <img
      src={props.portofolioImg}
      style={{
        width: "100%",
        height: "auto",
        borderRadius: "8px",
      }}
      alt="portofolio"
    />
  );
};

export default PortofolioImg;
