import React from "react";
import Header from "../../Header/Header";

const ServiceContentReverse = (props) => {
  const contentDataList = [
    {
      id: 1,
      title: "Lead developers",
      text: "your team will get one. They are most experienced developers who take care of the right software development strategy and help the team with following it",
    },
    {
      id: 2,
      title: "Interdisciplinary skills",
      text: "you can easily fill the gap between frontend and backend, and take advantage of our cloud experience",
    },
    {
      id: 3,
      title: "Delivery mindset",
      text: "we work on business-oriented goals, so you start generating value from technology fast",
    },
    {
      id: 4,
      title: "Effective communication",
      text: "you’ll quickly feel like we worked as your in-house team. Our process is fully transparent and cooperative. And obviously, everyone speaks English well.",
    },
  ];

  return (
    <div className="row service-content" id="service-content">
      <div className="col-md-6">
        <img
          src={props.imgUrl}
          alt="our-story"
          className=""
          style={{ width: "90%" }}
        ></img>
      </div>
      <div className="col-md-6 service-content-reverse">
        <Header
          styles=" mobile-text-center"
          header2=""
          header1="How we build React Native development team for you"
          text="You're guaranteed to work with top developers only. First of all, we
          set high recruitment standards - only 5% of applicants are offered a
          job. Secondly - we take care of developers' personal growth and
          motivation. Finally, we keep them effective and well-coordinated."
        />
        <ul>
          {contentDataList.map((data) => {
            return (
              <li className="d-flex" key={data.id}>
                <div className="me-2">
                  <img
                    src="Asset/Services/Website development/check.svg"
                    alt="check"
                  />
                </div>

                <p>
                  <b>{data.title}</b> - {data.text}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ServiceContentReverse;
