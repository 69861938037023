import React from "react";
import ServiceCard from "../../components/ServiceCard";
import "./Service.css";
import icons from "../../Asset/data/iconDataUrl";
import servicesData from "../../Asset/data/serviceData";
import Header from "../../components/Header/Header";

const Service = () => {
  return (
    <div id="services">
      <div className="container-md">
        <Header
          styles="text-center m-header"
          header2="Complete Package"
          header1="From product design to software continuous delivery"
          text=""
        />
        <div className="row">
          {servicesData.map((s) => {
            return (
              <ServiceCard
                img={s.imgUrl}
                linkTitle={s.title}
                link={s.link}
                desc={s.desc}
                key={s.id}
              ></ServiceCard>
            );
          })}
        </div>
        <div className="row mt-4">
          {icons.map((icon) => {
            return (
              <div className="t-icon col-lg-2 col-sm-3" key={icon.id}>
                <img
                  src={`/Asset/Home/TechLogo/${icon.imgUrl}`}
                  alt={icon.imgUrl}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Service;
