import React from "react";
import Header from "../../Header/Header";
import "./ServiceTech.css";

const ServiceTech = () => {
  return (
    <div className="service-tech text-center mb-4">
      <div className="service-tech-header">
        <Header
          styles="text-center"
          header2="Technology Stack"
          header1="Choose battle-tested web development services with community support over hype"
          text="Whatever web applications you’re building, we got your back at every
          stage of web development, including web design. We’ve built a core
          system for a bank, an MVP marketplace sold for millions, and other 5☆
          custom projects for 140+ companies worldwide. All in accordance with
          the client’s digital marketing strategy."
        />
      </div>
    </div>
  );
};

export default ServiceTech;
