const lowonganData = [
  {
    id: 1,
    link: "senior_react_native_developer",
    title: "Senior React Native Developer",
    position: "Supervisor",
    jobType: "Full Time",
    location: "Alam Sutera, Tangerang",
    requirements: [
      {
        id: 1,
        text: "Min. bachelor's degree, IT",
      },
      {
        id: 2,
        text: "At least 2 years of working experience",
      },
    ],
    skillsAndExperience: [
      {
        id: 1,
        text: "Extensive experience of react native and javascript",
      },
      {
        id: 2,
        text: "Strong understandiing programming principles (RESTful APIs, MVC, etc.)",
      },
      {
        id: 3,
        text: "Experience with both iOS and Android mobile app development",
      },
      {
        id: 4,
        text: "Experience in agile environment and a through understanding of the metodology",
      },
      {
        id: 5,
        text: "Experience of testing",
      },
    ],
  },
  {
    id: 2,
    link: "nodejs_developer",
    title: "Node.js Developer",
    position: "Staff",
    jobType: "Full Time",
    location: "Alam Sutera, Tangerang",
    requirements: [
      {
        id: 1,
        text: "Min. bachelor's degree, IT",
      },
      {
        id: 2,
        text: "At least 2 years of working experience",
      },
    ],
    skillsAndExperience: [
      {
        id: 1,
        text: "Extensive experience of node.js and javascript",
      },
      {
        id: 2,
        text: "Strong understandiing programming principles (RESTful APIs, MVC, etc.)",
      },
      {
        id: 3,
        text: "Experience with database(MySql, MongooDB)",
      },
      {
        id: 4,
        text: "Experience in agile environment and a through understanding of the metodology",
      },
      {
        id: 5,
        text: "Experience of testing",
      },
    ],
  },
];

export { lowonganData };
