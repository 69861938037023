import React from "react";
import Header from "../../../components/Header/Header";
import ServiceHero from "../../../components/ServiceComponents/Service-Hero/ServiceHero";

const CmsDevelopment = () => {
  const cmsData = [
    {
      id: 1,
      imgUrl:
        "/Asset/Services/CMS Development/Minimize application failures thanks to automated scalability that responds to fluctuating traffic.svg",
      title: "Performance",
      text: "Reach high-level project responsiveness and availability by using low-resource cloud components",
    },
    {
      id: 2,
      imgUrl: "/Asset/Services/CMS Development/Adaptability.svg",
      title: "Adaptability",
      text: "Add new features easier — be it a different front end or a process rule — with a software architecture that creates a clear separation of concerns",
    },
    {
      id: 3,
      imgUrl: "/Asset/Services/CMS Development/Cost optimization.svg",
      title: "Cost optimization",
      text: "Expand without overspending with pay-for-use technology that minimizes infrastructure operation costs",
    },
    {
      id: 4,
      imgUrl: "/Asset/Services/CMS Development/Security.svg",
      title: "Security",
      text: "Yes, experts agree it can be secure. Your new architecture will comply with best practices in user security and monitoring, information security, and application-level security",
    },
    {
      id: 5,
      imgUrl: "/Asset/Services/CMS Development/Reliability.svg",
      title: "Reliability",
      text: "Minimize application failures thanks to automated scalability that responds to fluctuating traffic",
    },
  ];

  return (
    <section id="cms-development">
      <div className="container">
        <ServiceHero
          header2="CMS development ready for your success"
          text="Technology moves faster than ever, and you need to catch up. Reach measurably higher scalability, performance, and cost optimization thanks to resilient software architecture development."
        />
        <div className="row mt-4 mb-4">
          <div className="col-md-6">
            <Header
              styles="mobile-text-center"
              header2="CMS development"
              header1="A well-designed software architecture saves thousands of development hours"
              text="We’ve seen it happen many times in our software engineering work for 200+ projects. Last client dropped server maintenance and operation costs by 300 times. Time to see what performance you can achieve."
              text2="Our software architects will help you with secure, flexible, and efficient software development process that meets your needs."
            />
          </div>
          <div className="col-md-6">
            <img
              src="/Asset/Services/CMS Development/Header.jpg"
              alt="our-story"
              className="content-img"
            ></img>
          </div>
        </div>
        <div className="row" style={{ marginTop: "100px" }}>
          {cmsData.map((data) => {
            return (
              <div className="col-md-4 d-flex" key={data.id}>
                <div className="me-2">
                  <img src={data.imgUrl} alt="CMS Development" />
                </div>
                <div>
                  <h3>{data.title}</h3>
                  <p>{data.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CmsDevelopment;
