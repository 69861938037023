import React from "react";
import PortofolioContent from "../../../components/Portofolio/PortofolioContent";
import PortofolioImg from "../../../components/Portofolio/PortofolioImg";
// import ServiceHero from "../../../components/ServiceComponents/Service-Hero/ServiceHero";

const Trek = () => {
  return (
    <section id="ilios">
      <div className="container" style={{ paddingTop: "60px" }}>
        <PortofolioImg portofolioImg={"/Asset/Portofolio/trek/Atas.png"} />
        <PortofolioContent
          title={"Trek App (CRM & POS)"}
          desc={
         "   Integrated Cloud based Complete CRM system that helps companies to plan, manage, and control activities, pre-sales, after sales in the companies Trek helps companies to manage and control their follow up activities from leads to sales."
          }
          image={"/Asset/Portofolio/trek/Tengah.png"}
        />
        <PortofolioImg
          portofolioImg={"/Asset/Portofolio/trek/Bawah.jpg"}
        />
      </div>
    </section>
  );
};

export default Trek;
