import React from "react";

const PortofolioContent = (props) => {
  return (
    <div className="portofolio-content">
      <div className="row" style={{ margin: "60px 0" }}>
        <div className="col-md-6">
          <h1>{props.title}</h1>
          <hr
            style={{
              border: "4px solid #FFCE07",
              width: "25%",
              borderRadius: "8px",
              marginBottom: "30px",
            }}
          />
          <p>{props.desc}</p>
        </div>
        <div className="col-md-6">
          <img
            src={props.image}
            style={{
              marginLeft: "auto",
              display: "block",
              maxWidth: "100%",
            }}
            alt="content-img"
          />
        </div>
      </div>
    </div>
  );
};

export default PortofolioContent;
