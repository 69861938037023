import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer p-4">
      <div className="container">
        <hr />
        <div className="row mt-5">
          <div className="col-lg-6">
            <img
              src="/Asset/Background+Icon/Logo.png"
              alt="logo"
              style={{ width: "125px" }}
            ></img>
            <p>
              Albatech acts as a corporate partner to help digitize their
              business to accelerate trends in remote work.
            </p>
            <div className="social-media">
              <a
                href="https://www.facebook.com/PT-Alba-Digital-Teknologi-107617371072128"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>
                  <img src="/Asset/Background+Icon/Fb.png" alt="facebook" />
                </span>
              </a>
              <a
                href="https://www.instagram.com/albatech.id/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>
                  <img src="/Asset/Background+Icon/Ig.png" alt="instagram" />
                </span>
              </a>
              <a
                href="https://www.linkedin.com/company/albatechindonesia/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>
                  <img src="/Asset/Background+Icon/Linked.png" alt="linkedin" />
                </span>
              </a>
            </div>
          </div>
          <div className="col-lg-3">
            <h4>Website</h4>
            <ul>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/service_website">Service</Link>
              </li>
              <li>
                <Link to="/portofolio">Portfolio</Link>
              </li>
              {/* <li>
                <Link to="/blog">Blog</Link>
              </li> */}
              <li>
                <Link to="/career">Career</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3">
            <h4>Contact</h4>
            <p>
              The Prominence Blok 38D No.25 Jl. Jalur Sutera Barat, Alam Sutera,
              Kota Tangerang, Banten 15143
            </p>
            <a
              href="https://wa.me/+6287897779893"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#000" }}
            >
              <p>
                <b>P</b>: +62 87 8977 79893
              </p>
            </a>
            <a
              href="mailto: info@albatech.id"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#000" }}
            >
              <p>
                <b>E</b>: info@albatech.id
              </p>
            </a>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <p>
            <span>©PT. Alba Digital Teknologi 2021 | All Rights Reserved</span>
          </p>
          <p>
            <span>Privacy policy | Terms of service</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
