const achivementData = [
  {
    id: 1,
    numb: 1000,
    title: "Designs",
  },
  {
    id: 2,
    numb: 30,
    title: "Products",
  },
  {
    id: 3,
    numb: 58,
    title: "Website Development",
  },
];

export default achivementData;
