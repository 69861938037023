import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import "./UnderConstructionPage.css";

const UnderConstructionPage = () => {
  return (
    <section className="under-construction d-flex justify-content-center align-items-center flex-column">
      <div>
        <h1>UNDER CONSTRUCTION</h1>
      </div>
      <Link to="/">
        <Button btnText="Back To Home" />
      </Link>

      <img
        src="/Asset/UnderConstruction/UnderConstructionImg.png"
        alt="underconstruction"
      />
    </section>
  );
};

export default UnderConstructionPage;
