import React from "react";
import { Link } from "react-router-dom";

const PortfolioCard = (props) => {
  return (
    <div className="col-lg-4">
      <Link className="card" to={`/${props.link}`}>
        <img src={props.imgUrl} className="card-img-top" alt="..." />
        <div className="card-body">
          <p>
            <span>{props.titleType}</span>
          </p>
          <h5 className="card-title">{props.titleName}</h5>
          <p className="card-text">{props.desc}</p>
        </div>
      </Link>
    </div>
  );
};

export default PortfolioCard;
