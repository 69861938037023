import React from "react";
import "./Button.css";

const WhatsAppButton = () => {
  return (
    <div>
      <div className="circle"></div>
      <div className="wa-btn">
        <a
          href="https://api.whatsapp.com/send?phone=6287897779893"
          target="_blank"
          rel="noreferrer"
        >
          <span>
            <img
              src="/Asset/Background+Icon/WhatsApp Button.png"
              alt="whatsapp"
            />
          </span>
        </a>
        <br />
      </div>
      <div>
        <p className="text_icon">Need Help ?</p>
      </div>
    </div>
  );
};

export default WhatsAppButton;
