import React from "react";
import PortofolioContent from "../../../components/Portofolio/PortofolioContent";
import PortofolioImg from "../../../components/Portofolio/PortofolioImg";

const Moves = () => {
  return (
    <section id="moves">
      <div className="container" style={{ paddingTop: "60px" }}>
        <PortofolioImg portofolioImg={"/Asset/Portofolio/Moves/Moves.png"} />
        <PortofolioContent
          title={"Moves"}
          //   desc={"Lorem ipsum"}
          image={"/Asset/Portofolio/Moves/Moves mobile.png"}
        />
        <PortofolioImg portofolioImg={"/Asset/Portofolio/Moves/Moves 2.png"} />
      </div>
    </section>
  );
};

export default Moves;
