import React from "react";
import PortofolioContent from "../../../components/Portofolio/PortofolioContent";
import PortofolioImg from "../../../components/Portofolio/PortofolioImg";

const PempekWarisan = () => {
  return (
    <section id="pempek">
      <div className="container" style={{ paddingTop: "60px" }}>
        <PortofolioImg
          portofolioImg={"/Asset/Portofolio/Pempek warisan/Pempek.png"}
        />
        {/* <ServiceHero imgBackground={"/Asset/Portofolio/ilios.png"} /> */}
        <PortofolioContent
          title={"Website Pempek Warisan"}
          desc={
            "AlbaTech helps in creating a webstore with feature to show catalog product and brand information."
          }
          image={"/Asset/Portofolio/Pempek warisan/Pempek mobile.png"}
        />
        <PortofolioImg
          portofolioImg={"/Asset/Portofolio/Pempek warisan/Pempek 2.png"}
        />
      </div>
    </section>
  );
};

export default PempekWarisan;
