import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { lowonganData } from "./CareerData";
import "./Career.css";

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [position, setPosition] = useState("");
  const [jobType, setJobType] = useState("");
  const [location, setLocation] = useState("");

  return (
    <section id="career">
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="career-hero row">
          <div className="col-md-6">
            <img src="/Asset/Career/Frame.png" alt="career" />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="text-center text-md-start">
              <h1>Loyal, Enthusiast, Passionate, Creative</h1>
              <p>
                ALBA become our second home where technology & creativity fused
                into digital chemistry. It’s time to develop and get best
                experience for your career.
              </p>
            </div>
          </div>
        </div>
        <div className="career-search-form mt-5">
          <h1>Discover your passion with us</h1>
          <div className="row">
            <div className="col-md-3">
              <select
                className="form-select"
                name="position"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              >
                <option value="">All position</option>
                <option value="Supervisor">Supervisor</option>
                <option value="Staff">Staff</option>
              </select>
            </div>
            <div className="col-md-3">
              <select
                className="form-select"
                name="jobType"
                value={jobType}
                onChange={(e) => setJobType(e.target.value)}
              >
                <option value="">All job type</option>
                <option value="Full Time">Full Time</option>
                <option value="Internship">Internship</option>
              </select>
            </div>
            <div className="col-md-3">
              <select
                className="form-select"
                name="service"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                <option value="">All Location</option>
                <option value="Alam Sutera, Tangerang">
                  Alam Sutera, Tangerang
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="career-job mt-5">
          <div className="row">
            {lowonganData
              .filter((lowongan) => {
                if (position === "" && jobType === "" && location === "") {
                  return lowongan;
                } else if (
                  lowongan.jobType
                    .toLowerCase()
                    .includes(jobType.toLowerCase()) &&
                  lowongan.position
                    .toLowerCase()
                    .includes(position.toLowerCase()) &&
                  lowongan.location
                    .toLowerCase()
                    .includes(location.toLowerCase())
                ) {
                  return lowongan;
                } else {
                  return null;
                }
              })
              .map((lowongan) => {
                return (
                  <div className="col-md-4" key={lowongan.id}>
                    <div className="career-card">
                      <h3 className="mb-4">{lowongan.title}</h3>
                      <div className="d-flex mb-2">
                        <img src="/Asset/Career/Profile.png" alt="Profile" />
                        <p>{lowongan.position}</p>
                      </div>
                      <div className="d-flex mb-2">
                        <img src="/Asset/Career/Job.png" alt="Job" />
                        <p>{lowongan.jobType}</p>
                      </div>
                      <div className="d-flex mb-2">
                        <img src="/Asset/Career/Location.png" alt="Location" />
                        <p>{lowongan.location}</p>
                      </div>
                      <Link to={`/${lowongan.link}`}>
                        More Detail
                        <img src="/Asset/aboutUs/Arrow.png" alt="arrow" />
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Career;
