import React from "react";
import PortofolioContent from "../../../components/Portofolio/PortofolioContent";
import PortofolioImg from "../../../components/Portofolio/PortofolioImg";
// import ServiceHero from "../../../components/ServiceComponents/Service-Hero/ServiceHero";

const Ilios = () => {
  return (
    <section id="ilios">
      <div className="container" style={{ paddingTop: "60px" }}>
        <PortofolioImg portofolioImg={"/Asset/Portofolio/Ilios/ilios.png"} />
        <PortofolioContent
          title={"ILIOS app (B2B E-commerce)"}
          desc={
            "Has a unique selling point where not all companies offer applications for sales, data collection and management. Alba Digital Technology is able to offer investment for business development from the grip of a mobile phone. Currently, the ILIOS application helps clients to manage their customers (reseller, master dealer and end user) to make purchases from one mobile application that published on App Store and Play Store, thereby speeding up the transaction process and the business being carried out."
          }
          image={"/Asset/Portofolio/Ilios/ilios mobile.png"}
        />
        <PortofolioImg
          portofolioImg={"/Asset/Portofolio/Ilios/ilios bg2.png"}
        />
      </div>
    </section>
  );
};

export default Ilios;
