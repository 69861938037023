import React from "react";
import PortofolioContent from "../../../components/Portofolio/PortofolioContent";
import PortofolioImg from "../../../components/Portofolio/PortofolioImg";

const Hippo = () => {
  return (
    <section id="hippo">
      <div className="container" style={{ paddingTop: "60px" }}>
        <PortofolioImg portofolioImg={"/Asset/Portofolio/Hippo/Hippo.png"} />
        {/* <ServiceHero imgBackground={"/Asset/Portofolio/ilios.png"} /> */}
        <PortofolioContent
          title={"Website Hippo"}
          desc={
            "AlbaTech helps in creating a webstore with feature to show catalog product and brand information."
          }
          image={"/Asset/Portofolio/Hippo/Hippo mobile.png"}
        />
        <PortofolioImg portofolioImg={"/Asset/Portofolio/Hippo/Hippo 2.png"} />
      </div>
    </section>
  );
};

export default Hippo;
