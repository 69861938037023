import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./CareerDetail.css";

const CareerDetail = (props) => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cv, setCv] = useState("");
  const [portfolio, setPortfolio] = useState("");

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      fullname: fullname,
      email: email,
      phoneNumber: phoneNumber,
      cv: cv,
      portfolio: portfolio,
      jobTitle: props.title,
    };
    emailjs
      .send("service_p3xxxef", "template_axohnbt", data, "Kub04WK8Jf7vf6SqK")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setFullname("");
    setEmail("");
    setPhoneNumber("");
    setCv("");
    setPortfolio("");
  };

  return (
    <section id="career-detail">
      <div className="container" style={{ paddingTop: "60px" }}>
        <h1 className="mb-4">{props.title}</h1>

        <div className="d-flex mb-2">
          <img src="/Asset/Career/Profile.png" alt="Profile" />
          <p>{props.position}</p>
        </div>
        <div className="d-flex mb-2">
          <img src="/Asset/Career/Job.png" alt="Job" />
          <p>{props.jobType}</p>
        </div>
        <div className="d-flex mb-2">
          <img src="/Asset/Career/Location.png" alt="Location" />
          <p>{props.location}</p>
        </div>
        <hr />
        <h3 className="mb-3">Requirements</h3>
        <ul>
          {props.requirements.map((requirement) => {
            return <li key={requirement.id}>{requirement.text}</li>;
          })}
        </ul>
        <h3 className="mb-3">Skills and Experience</h3>
        <ul>
          {props.skillsAndExperience.map((d) => {
            return <li key={d.id}>{d.text}</li>;
          })}
        </ul>
        <hr />
        <form ref={form} onSubmit={handleSubmit}>
          <h3>Apply for this position</h3>
          <div className="mb-3">
            <label>
              Full name<span>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="fullname"
              name="fullname"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label>
              Email<span>*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label>
              Phone number<span>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">
              Upload CV/Resume<span>*</span>
            </label>
            <input
              className="form-control"
              type="file"
              id="cv"
              name="cv"
              value={cv}
              onChange={(e) => setCv(e.target.value)}
            />
            <p>Allowed Type(s): .pdf, .doc, .docx</p>
          </div>
          <div className="mb-3">
            <label>Attach portfolio link</label>
            <input
              type="text"
              className="form-control"
              id="portfolio"
              name="portfolio"
              value={portfolio}
              onChange={(e) => setPortfolio(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <button className="btn btn-yellow" style={{ width: "100%" }}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CareerDetail;
