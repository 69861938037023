import React from "react";
import PortofolioContent from "../../../components/Portofolio/PortofolioContent";
import PortofolioImg from "../../../components/Portofolio/PortofolioImg";

const HomeAndLiving = () => {
  return (
    <section id="home-and-living">
      <div className="container" style={{ paddingTop: "60px" }}>
        <PortofolioImg
          portofolioImg={
            "/Asset/Portofolio/Home and living/Home and living.png"
          }
        />
        {/* <ServiceHero imgBackground={"/Asset/Portofolio/ilios.png"} /> */}
        <PortofolioContent
          title={"Website Home & Living"}
          desc={
            "AlbaTech helps in creating a website catalog to show brand identity and information in accordance with vision and mission of Home and Living. "
          }
          image={"/Asset/Portofolio/Home and living/Home and living mobile.png"}
        />
        <PortofolioImg
          portofolioImg={
            "/Asset/Portofolio/Home and living/Home and living 2.png"
          }
        />
      </div>
    </section>
  );
};

export default HomeAndLiving;
