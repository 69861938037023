import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import CarouselBrand from "../../components/CarouselBrand";
import Achivement from "./Achivement";
import Hero from "./Hero";
import Portfolio from "./Portfolio";
import Service from "./Service";
// import Testimonials from "./Testimonials";

const Landing = () => {
  return (
    <section>
      <Hero />
      <Service />
      <Achivement />
      <Portfolio />
      {/* <Testimonials /> */}
      <div className="container">
        <CarouselBrand />
        <div
          className="d-flex justify-content-center text-center"
          style={{ marginTop: "100px" }}
        >
          <div className="consultation">
            <h3>Is software important for your business?</h3>
            <h1>Build it with Albatech</h1>
            <Link to="/contact">
              <Button btnText="Consultation Now" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
