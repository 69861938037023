import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Asset/Logo.png";
import Button from "../Button/Button";
import "./Navbar.css";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
  };

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const serviceDataNav = [
    {
      id: 1,
      title: "Website development",
      link: "/service_website",
    },
    {
      id: 2,
      title: "Maintenance",
      link: "/maintenance",
    },
    {
      id: 3,
      title: "Mobile apps development",
      link: "/service_mobile",
    },
    {
      id: 4,
      title: "CMS development",
      link: "/cms_development",
    },
    {
      id: 5,
      title: "Digital product design",
      link: "/digital_product_design",
    },
    {
      id: 6,
      title: "Integrated Payment Gateway",
      link: "/payment_gateway",
    },
  ];

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-md">
          <Link to="/" onClick={closeMobileMenu} className="navbar-brand">
            <img
              src={Logo}
              className="navbar-logo"
              style={{ width: "125px" }}
              alt="logo.png"
            ></img>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={`${click ? "active" : ""} navbar-nav`}>
            <li className="nav-item">
              <Link to="/about" className="nav-link" onClick={closeMobileMenu}>
                About
              </Link>
            </li>
            <li className="nav-item dd">
              <div className="nav-link" role="button" onClick={handleDropdown}>
                Service
              </div>
              <ul className={`ddown row ${dropdown && `active`}`}>
                {serviceDataNav.map((data) => {
                  return (
                    <li className="col-md-6 dropdown_animated" key={data.id}>
                      <Link
                        to={data.link}
                        className="nav-link"
                        onClick={() => {
                          handleDropdown();
                          closeMobileMenu();
                        }}
                      >
                        {data.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li className="nav-item" onClick={closeMobileMenu}>
              <Link to="/portofolio" className="nav-link">
                Portfolio
              </Link>
            </li>
            {/* <li className="nav-item" onClick={closeMobileMenu}>
              <Link to="/blog" className="nav-link">
                Blog
              </Link>
            </li> */}
            <li className="nav-item" onClick={closeMobileMenu}>
              <Link to="/career" className="nav-link">
                Career
              </Link>
            </li>
            <Link to="/contact">
              <Button btnText="Contact Us" />
            </Link>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
