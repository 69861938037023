const portofolioData = [
  {
    id: 1,
    imgUrl: "/Asset/Portofolio/Ilios.png",
    link: "ilios",
    serviceCategory: ["digital_product_design", "mobile_apps_development"],
    businessCategory: "retails",
    title: "Ilios Apps",
    type: "Deliverables :",
    desc: "Digital product design, Mobile apps development",
  },
  {
    id: 7,
    imgUrl: "/Asset/Portofolio/trek/porto.png",
    link: "trek",
    serviceCategory: ["digital_product_design", "mobile_apps_development"],
    businessCategory: "retails",
    title: "Trek Apps",
    type: "Deliverables :",
    desc: "Digital product design, Mobile apps development",
  },
  {
    id: 2,
    imgUrl: "/Asset/Portofolio/Home and living.png",
    link: "home_and_living",
    serviceCategory: [
      "cms_development",
      "digital_product_design",
      "website_development",
    ],
    businessCategory: "furniture",
    title: "Website Home and Living",
    type: "Deliverables :",
    desc: "CMS development, Digital product design, Website Development",
  },
  {
    id: 3,
    imgUrl: "/Asset/Portofolio/Pempek.png",
    link: "pempek_warisan",
    serviceCategory: [
      "cms_development",
      "digital_product_design",
      "website_development",
      "integrated_payment_gateway",
    ],
    businessCategory: "food_&_beverage",
    title: "Website Pempek Warisan",
    type: "Deliverables",
    desc: "CMS development, Digital product design, Website Development, Integrated payment gateway",
  },
  {
    id: 4,
    imgUrl: "/Asset/Portofolio/Hippo.png",
    link: "hippo",
    serviceCategory: [
      "cms_development",
      "digital_product_design",
      "website_development",
      "integrated_payment_gateway",
    ],
    businessCategory: "retails",
    title: "Website Hippo",
    type: "Deliverables",
    desc: "CMS development, Digital product design, Website Development, Integrated payment gateway",
  },
  {
    id: 5,
    imgUrl: "/Asset/Portofolio/Moves.png",
    link: "moves",
    serviceCategory: ["digital_product_design", "mobile_apps_development"],
    businessCategory: "retails",
    title: "Moves",
    type: "Deliverables",
    desc: "Digital product design, Mobile apps development",
  },
];

export { portofolioData };
