import React from "react";
import "./ServiceCarousel.css";
import ServiceCarouselContent from "./ServiceCarouselContent";

const buttonData = [
  {
    id: 0,
    title: "Node.js",
    imgUrl: "Asset/Services/Website development/Node JS.svg",
    label: "slide 1",
  },
  {
    id: 1,
    title: "React.js",
    imgUrl: "Asset/Services/Website development/React.svg",
    label: "slide 2",
  },
  {
    id: 2,
    title: "Laravel",
    imgUrl: "Asset/Services/Website development/Laravel.svg",
    label: "slide 3",
  },
  {
    id: 3,
    title: "Wordpress",
    imgUrl: "Asset/Services/Website development/Wordpress.svg",
    label: "slide 4",
  },
];

const listDataNode = [
  {
    id: 0,
    list: "Develop microservices",
  },
  {
    id: 1,
    list: "Optimized the app for high scalability",
  },
  {
    id: 2,
    list: "Enable live data processing",
  },
  {
    id: 3,
    list: "Use Node.js for frontend and backend",
  },
];

const listDataReact = [
  {
    id: 0,
    list: "Structure the build for A-grade performance",
  },
  {
    id: 1,
    list: "Help you reach better page indexing",
  },
  {
    id: 2,
    list: "Develop micro frontends",
  },
  {
    id: 3,
    list: "Work with smart components you can reuse",
  },
];

const listDataLaravel = [
  {
    id: 0,
    list: "Provide you with solid PHP code that functions for years",
  },
  {
    id: 1,
    list: "Use pre-built components to deliver in no-time",
  },
  {
    id: 2,
    list: "Document your application in detail",
  },
];

const listDataWordpress = [
  {
    id: 0,
    list: "Provide you with solid PHP code that functions for years",
  },
  {
    id: 1,
    list: "Use pre-built components to deliver in no-time",
  },
  {
    id: 2,
    list: "Document your application in detail",
  },
];

const ServiceCarousel = () => {
  return (
    <div className="service-carousel">
      <div
        id="serviceCarousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="d-flex justify-content-between align-items-center mb-4">
          {buttonData.map((d) => {
            return (
              <button
                key={d.id}
                type="button"
                data-bs-target="#serviceCarousel"
                data-bs-slide-to={d.id}
                className="carousel-btn btn col-3"
                aria-label={d.label}
              >
                {d.imgUrl && <img src={d.imgUrl} alt="Node.js" className="" />}

                {d.title}
              </button>
            );
          })}
        </div>

        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="10000">
            <ServiceCarouselContent
              text1="Because of an abundance of requests for Node.js developers, we formed Poland’s biggest team that will help you develop scalable network web applications made for top performance and real-time data analysis."
              text2="This team uses Node.js with TypeScript, GraphQL, and AWS. Some of their projects include building a video platform MVP done in 2 weeks for StageClip or Germany’s second biggest ticketing platform for Reservix."
              lists={listDataNode}
            />
          </div>
          <div className="carousel-item " data-bs-interval="10000">
            <ServiceCarouselContent
              text1="Secure maximum interactivity with a team specialized in the 2nd most popular web framework of 2020 that made Facebook a surprisingly fast global platform."
              text2="This team uses React.js, TypeScript & Next.js. Some of their projects include building a marketplace for commercial properties in the UK or Synerise’s customer dashboards for an AI-driven data and automation platform."
              lists={listDataReact}
            />
          </div>
          <div className="carousel-item " data-bs-interval="10000">
            <ServiceCarouselContent
              text1="Know how to make Laravel work with and not against your business goals. With the support of our PHP talent and one of the largest GiHub tech communities, you’ll see more delivered in one sprint throughout the project."
              text2="This team uses Horizon, REST API, Docker, and AWS. Some of their projects include the re-development of a speaker booking service used by Toshiba, BP, and Volvo, or web development and automation for Testaviva, a legal counseling platform operating in Denmark."
              lists={listDataLaravel}
            />
          </div>
          <div className="carousel-item " data-bs-interval="10000">
            <ServiceCarouselContent
              text1="Know how to make Laravel work with and not against your business goals. With the support of our PHP talent and one of the largest GiHub tech communities, you’ll see more delivered in one sprint throughout the project."
              text2="This team uses Horizon, REST API, Docker, and AWS. Some of their projects include the re-development of a speaker booking service used by Toshiba, BP, and Volvo, or web development and automation for Testaviva, a legal counseling platform operating in Denmark."
              lists={listDataWordpress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCarousel;
