import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
// import Login from "./Pages/AuthPages/Login";
import Landing from "./Pages/LandingPages/Landing";
import WhatsAppButton from "./components/Button/WhatsAppButton";
import AboutUs from "./Pages/AboutUsPages/AboutUs";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Service_WebsiteDevelopment from "./Pages/Service/Website Development/Service_WebsiteDevelopment";
import Service_Mobile from "./Pages/Service/Mobile Apps Development/Service_Mobile";
import UnderConstructionPage from "./Pages/UnderConstructionPage/UnderConstructionPage";
import Maintenance from "./Pages/Service/Maintenance/Maintenance";
import CmsDevelopment from "./Pages/Service/CMS Development/CmsDevelopment";
import PaymentGateway from "./Pages/Service/Integrated Payment Gateway/PaymentGateway";
import Service_DigitalProductDesign from "./Pages/Service/Digital product design/Service_DigitalProductDesign";
import Contact from "./Pages/Contact/Contact";
import Portofolio from "./Pages/Portofolio/Portofolio";
import Ilios from "./Pages/Portofolio/Portofolio Content Page/Ilios";
import HomeAndLiving from "./Pages/Portofolio/Portofolio Content Page/HomeAndLiving";
import PempekWarisan from "./Pages/Portofolio/Portofolio Content Page/PempekWarisan";
import Hippo from "./Pages/Portofolio/Portofolio Content Page/Hippo";
import Moves from "./Pages/Portofolio/Portofolio Content Page/Moves";
import Career from "./Pages/Career/Career";
import CareerDetail from "./Pages/Career/CareerDetail/CareerDetail";
import { lowonganData } from "./Pages/Career/CareerData";
import Trek from "./Pages/Portofolio/Portofolio Content Page/trek";
import SNK from "./Pages/snk";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Landing} />
        {/* <Route path="/login" component={Login} /> */}
        <Route path="/about" component={AboutUs} />
        <Route path="/service_website" component={Service_WebsiteDevelopment} />
        <Route path="/service_mobile" component={Service_Mobile} />
        <Route
          path="/digital_product_design"
          component={Service_DigitalProductDesign}
        />
        <Route path="/maintenance" component={Maintenance} />
        <Route path="/cms_development" component={CmsDevelopment} />
        <Route path="/payment_gateway" component={PaymentGateway} />
        <Route path="/portofolio" component={Portofolio} />
        <Route path="/ilios" component={Ilios} />
        <Route path="/trek" component={Trek} />
        <Route path="/home_and_living" component={HomeAndLiving} />
        <Route path="/pempek_warisan" component={PempekWarisan} />
        <Route path="/hippo" component={Hippo} />
        <Route path="/moves" component={Moves} />
        <Route path="/career" component={Career} />
        <Route path="/snk" component={SNK} />
        {lowonganData.map((lowongan) => {
          return (
            <Route path={`/${lowongan.link}`} key={lowongan.id}>
              <CareerDetail
                title={lowongan.title}
                position={lowongan.position}
                jobType={lowongan.jobType}
                location={lowongan.location}
                requirements={lowongan.requirements}
                skillsAndExperience={lowongan.skillsAndExperience}
              />
            </Route>
          );
        })}

        <Route path="/contact" component={Contact} />
        <Route path="/underconstruction" component={UnderConstructionPage} />
        <Redirect to="/underconstruction" />
      </Switch>
      <Footer />
      <WhatsAppButton />
    </Router>
  );
}

export default App;
