import React from "react";
import "./AboutUsHero.css";

const AboutUsHero = () => {
  return (
    <section id="aboutus-hero">
      <div className="container-md">
        <div className="wrapper">
          <h1>
            We think you should feel supported by the people you work with
          </h1>
          <p>
            The quality of our software development comes from how we treat each
            other. Our talent remains approachable and ready to lend a hand
            because it’s the right thing to do.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUsHero;
