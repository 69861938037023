import React from "react";
import ServiceCarousel from "../../../components/ServiceComponents/Service-carousel-web/ServiceCarousel";
import ServiceContent from "../../../components/ServiceComponents/Service-content/ServiceContent";
import ServiceHero from "../../../components/ServiceComponents/Service-Hero/ServiceHero";
import ServiceTech from "../../../components/ServiceComponents/Service-TechnologyStack/ServiceTech";
import ServiceCard from "../../../components/ServiceComponents/ServiceCard/ServiceCard";
import Cards from "./ServiceCardData";

const Service_WebsiteDevelopment = () => {
  return (
    <section>
      <div className="container">
        <ServiceHero
          header1={"Full stack web development"}
          header2={
            "Deliver great user experience with proven web development services"
          }
          text={
            "There’s a project you need done well and fast. We’ve helped with digital transformation over 200x times. Develop with confidence with The Software House’s complete product team that follows best practices of web development services tested in 7 markets."
          }
        />
        <ServiceContent imgUrl="/Asset/Services/Website development/Image.jpg" />
        <div className="serviceCards" style={{ marginTop: "80px" }}>
          <div className="row ">
            {Cards.map((data) => {
              return (
                <div className="serviceCard col-md-4" key={data.id}>
                  <ServiceCard
                    title={data.title}
                    text={data.text}
                    img={data.imgUrl}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <ServiceTech />
        <ServiceCarousel />
      </div>
    </section>
  );
};

export default Service_WebsiteDevelopment;
