import React from "react";
import Header from "../../Header/Header";
import "./ServiceContent.css";

const ServiceContent = (props) => {
  return (
    <div className="row service-content" id="service-content">
      <div className="col-md-6">
        {/* <h2>Less manaement, more results</h2>
        <h1>One scalable team for start-to-finish development</h1> */}
        <Header
          textAlign=""
          header2="Less manaement, more results"
          header1="One scalable team for start-to-finish development"
          text=""
        />
        <p>
          What does it mean to be reliable? It means your outsourced team works
          as well as your web developers do. Access web development services
          trusted by 98% of CTOs that you’ll find understanding with. They made
          APIs, custom web applications, hybrid mobile app development, updated
          stacks, and build cloud-native systems.
        </p>
        <p>
          One place — everyone you need: Business Analysts, Product Designers,
          Project Managers, QA Engineers, DevOps, and Full-stack developers.
          Expect our web development team to be proactive throughout the project
          with an abundance of improvement suggestions that will get business
          moving even in areas such as search engine optimization.
        </p>
      </div>
      <div className="col-md-6">
        <img src={props.imgUrl} alt="our-story" className="content-img"></img>
      </div>
    </div>
  );
};

export default ServiceContent;
