const servicesData = [
  {
    id: "1",
    imgUrl: "Asset/Home/Icon/Website development.png",
    title: "Website development",
    link: "/service_website",
    desc: "High-performance web applications that grow with your business",
  },
  {
    id: "2",
    imgUrl: "Asset/Home/Icon/Mobile apps development.png",
    title: "Mobile apps development",
    link: "/service_mobile",
    desc: "Mobile app also become central role in the day-to-day operations of the business.",
  },
  {
    id: "3",
    imgUrl: "Asset/Home/Icon/Digital product design.png",
    title: "Digital product design",
    link: "/",
    desc: "Complete digital product creations from UX prototyping to final UI designs",
  },
  {
    id: "4",
    imgUrl: "Asset/Home/Icon/Maintenance.png",
    title: "Maintanance",
    link: "/",
    desc: "Maintaining a scalable and secure architecture",
  },
  {
    id: "5",
    imgUrl: "Asset/Home/Icon/CMS development.png",
    title: "CMS development",
    link: "/",
    desc: "Developing a resilient software architecture centered on scalability",
  },
  {
    id: "6",
    imgUrl: "Asset/Home/Icon/Payment.png",
    title: "Integrated Payment Gateway",
    link: "/",
    desc: "Simplify the payment system with just one step",
  },
];

export default servicesData;
