import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { Link, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import "./Portofolio.css";
import PortofolioCard from "./PortofolioCard";
import { portofolioData } from "./PortofolioData";

const Portofolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [titleSearch, setTitleSearch] = useState("");

  const sidebarData = [
    {
      id: 1,
      title: "Service",
      lists: [
        {
          id: 1,
          title: "CMS development",
          quantity: "1",
          link: "cms_development",
        },
        {
          id: 2,
          title: "Digital product design",
          quantity: "1",
          link: "digital_product_design",
        },
        {
          id: 3,
          title: "Integrated payment gateway",
          quantity: "1",
          link: "integrated_payment_gateway",
        },
        {
          id: 4,
          title: "Maintenance",
          quantity: "1",
          link: "maintenance",
        },
        {
          id: 5,
          title: "Mobile apps development",
          quantity: "1",
          link: "mobile_apps_development",
        },
        {
          id: 6,
          title: "Website development",
          quantity: "1",
          link: "website_development",
        },
      ],
    },
    {
      id: 2,
      title: "Business Category",
      lists: [
        {
          id: 1,
          title: "Education",
          quantity: "2",
          link: "education",
        },
        {
          id: 2,
          title: "Food & Beverage",
          quantity: "1",
          link: "food_&_beverage",
        },
        {
          id: 3,
          title: "Retails",
          quantity: "2",
          link: "retails",
        },
        {
          id: 4,
          title: "Furniture",
          quantity: "3",
          link: "furniture",
        },
        {
          id: 5,
          title: "Technology",
          quantity: "2",
          link: "technology",
        },
      ],
    },
  ];
  const filterMultipleCategories = (arr, category) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === category) {
        return arr[i];
      }
    }
  };

  const searchHandle = (e) => {
    setTitleSearch(e.target.value);
  };

  let match = useRouteMatch();
  return (
    <section id="portofolio">
      <div className="container">
        <form className="search-form">
          <input
            className="form-control"
            type="text"
            placeholder="Search"
            value={titleSearch}
            onChange={searchHandle}
          />
        </form>
        <div className="row">
          <div className="sidebar col-md-3">
            {sidebarData.map((data) => {
              return (
                <ul key={data.id}>
                  <h3>{data.title}</h3>
                  {data.lists.map((list) => {
                    return (
                      <li
                        className="d-flex justify-content-between mb-1"
                        key={list.id}
                      >
                        <Link to={`${match.url}/${list.link}`}>
                          {list.title}
                        </Link>
                        <span>
                          (
                          {portofolioData.filter((portofolio) =>
                            filterMultipleCategories(
                              portofolio.serviceCategory,
                              list.link
                            )
                          ).length ||
                            portofolioData.filter(
                              (portofolio) =>
                                portofolio.businessCategory === list.link
                            ).length}
                          )
                        </span>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </div>
          <div className="portofolio-content col-md-9">
            <div className="row">
              <Switch>
                <Route path={`${match.path}/`} exact>
                  {portofolioData
                    .filter((portofolio) => {
                      if (titleSearch === "") {
                        return portofolio;
                      } else if (
                        portofolio.title
                          .toLowerCase()
                          .includes(titleSearch.toLowerCase())
                      ) {
                        return portofolio;
                      } else {
                        return null;
                      }
                    })
                    .map((portofolio) => {
                      return (
                        <PortofolioCard
                          key={portofolio.id}
                          link={portofolio.link}
                          title={portofolio.title}
                          type={portofolio.type}
                          desc={portofolio.desc}
                          imgUrl={portofolio.imgUrl}
                        />
                      );
                    })}
                </Route>
                {sidebarData[0].lists.map((list) => {
                  return (
                    <Route path={`${match.path}/${list.link}`} key={list.id}>
                      {portofolioData
                        .filter((portofolio) =>
                          filterMultipleCategories(
                            portofolio.serviceCategory,
                            list.link
                          )
                        )
                        .map((portofolio) => {
                          return (
                            <PortofolioCard
                              key={portofolio.id}
                              link={portofolio.link}
                              title={portofolio.title}
                              type={portofolio.type}
                              desc={portofolio.desc}
                              imgUrl={portofolio.imgUrl}
                            />
                          );
                        })}
                    </Route>
                  );
                })}
                {sidebarData[1].lists.map((list) => {
                  return (
                    <Route path={`${match.path}/${list.link}`} key={list.id}>
                      {portofolioData
                        .filter(
                          (portofolio) =>
                            portofolio.businessCategory === list.link
                        )
                        .map((portofolio) => {
                          return (
                            <PortofolioCard
                              key={portofolio.id}
                              link={portofolio.link}
                              title={portofolio.title}
                              type={portofolio.type}
                              desc={portofolio.desc}
                              imgUrl={portofolio.imgUrl}
                            />
                          );
                        })}
                    </Route>
                  );
                })}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portofolio;
