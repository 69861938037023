import React from "react";
import "./ServiceCarouselMobile.css";

const ServiceCarouseMobile = () => {
  const buttonData = [
    {
      id: 0,
      title: "Mobile applications for Android",
      imgUrl: "",
      label: "slide 1",
    },
    {
      id: 1,
      title: "Mobile application for iOS",
      imgUrl: "",
      label: "slide 2",
    },
    {
      id: 2,
      title: "Highly-interactive user interfaces",
      imgUrl: "",
      label: "slide 3",
    },
    {
      id: 3,
      title: "Cross-platform mobile apps",
      imgUrl: "",
      label: "slide 4",
    },
    {
      id: 4,
      title: "High-performing applications",
      imgUrl: "",
      label: "slide 5",
    },
  ];

  const carouselContentData = [
    {
      id: 0,
      title: "Mobile applications for Android",
      status: "active",
      text: "React Native development services can be used to develop Android applications that feel and perform in every way as well as mobile apps written in Android’s native language (Java). What’s more, developers can use both JavaScript and Java simultaneously. By communicating with the native SDK and working in the background to bring UI elements to the user, it doesn’t run into many performance problems typical for frameworks that take advantage of WebView.",
    },
    {
      id: 1,
      title: "Mobile application for iOS",
      status: "",
      text: "React Native development services can be used to develop Android applications that feel and perform in every way as well as mobile apps written in Android’s native language (Java). What’s more, developers can use both JavaScript and Java simultaneously. By communicating with the native SDK and working in the background to bring UI elements to the user, it doesn’t run into many performance problems typical for frameworks that take advantage of WebView.",
    },
    {
      id: 2,
      title: "Highly-interactive user interfaces",
      status: "",
      text: "React Native development services can be used to develop Android applications that feel and perform in every way as well as mobile apps written in Android’s native language (Java). What’s more, developers can use both JavaScript and Java simultaneously. By communicating with the native SDK and working in the background to bring UI elements to the user, it doesn’t run into many performance problems typical for frameworks that take advantage of WebView.",
    },
    {
      id: 3,
      title: "Cross-platform mobile apps",
      status: "",
      text: "React Native development services can be used to develop Android applications that feel and perform in every way as well as mobile apps written in Android’s native language (Java). What’s more, developers can use both JavaScript and Java simultaneously. By communicating with the native SDK and working in the background to bring UI elements to the user, it doesn’t run into many performance problems typical for frameworks that take advantage of WebView.",
    },
    {
      id: 4,
      title: "High-performing applications",
      status: "",
      text: "React Native development services can be used to develop Android applications that feel and perform in every way as well as mobile apps written in Android’s native language (Java). What’s more, developers can use both JavaScript and Java simultaneously. By communicating with the native SDK and working in the background to bring UI elements to the user, it doesn’t run into many performance problems typical for frameworks that take advantage of WebView.",
    },
  ];

  return (
    <div className="service-carousel-mobile">
      <div
        id="serviceCarouselMobile"
        className="carousel slide row ms-4 me-4"
        data-bs-ride="carousel"
      >
        <div className="col-5   d-flex flex-column">
          {buttonData.map((d) => {
            return (
              <button
                key={d.id}
                type="button"
                data-bs-target="#serviceCarouselMobile"
                data-bs-slide-to={d.id}
                className="carousel-btn btn mb-1"
                aria-label={d.label}
              >
                {d.imgUrl && (
                  <img src={d.imgUrl} alt="Node.js" className="me-3" />
                )}
                {d.title}
              </button>
            );
          })}
        </div>

        <div className="col-7 carousel-inner">
          {carouselContentData.map((d) => {
            return (
              <div
                className={`carousel-item ${d.status}`}
                data-bs-interval="10000"
                key={d.id}
              >
                <h5>{d.title}</h5>
                <p>{d.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServiceCarouseMobile;
