import React from "react";
import "./Header.css";

const Header = (props) => {
  return (
    <div className={`header ${props.styles}`}>
      {props.header2 && <h2>{props.header2}</h2>}
      <h1>{props.header1}</h1>
      <p>{props.text}</p>
      {props.text2 && <p>{props.text2}</p>}
    </div>
  );
};

export default Header;
