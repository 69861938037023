import React from "react";
import "./Portfolio.css";
import PortfolioCard from "../../components/PortfolioCard";
import portfolioData from "../../Asset/data/portfolioData";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";

const Portfolio = () => {
  return (
    <div id="portfolio" className="portfolio">
      <div className="container-md">
        <Header
          styles="text-center m-header"
          marginHeader="m-header"
          header2="Portfolio"
          header1="The software that we build takes our clients to the next level"
          text=""
        />
        <div className="row mb-4">
          {portfolioData.map((d) => {
            return (
              <PortfolioCard
                imgUrl={d.imgUrl}
                titleType={d.titleType}
                titleName={d.titleName}
                desc={d.desc}
                key={d.id}
                link={d.link}
              />
            );
          })}
        </div>
        <div className="tex-center d-flex justify-content-center">
          <Link to="/portofolio">
            <Button btnText="Learn more" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
