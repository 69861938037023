import React from "react";
import { Link } from "react-router-dom";

const PortofolioCard = (props) => {
  return (
    <div className="portofolio-card col-6">
      <Link className="card" to={`/${props.link}`}>
        <img src={props.imgUrl} alt="Jago" />
        <div className="portofolio-desc">
          <h4>{props.title}</h4>
          <h5>{props.type}</h5>
          <p>{props.desc}</p>
        </div>
      </Link>
    </div>
  );
};

export default PortofolioCard;
