import React from "react";
import Header from "../../../components/Header/Header";
import "./Service_DigitalProductDesign.css";

const Service_DigitalProductDesign = () => {
  return (
    <section id="digital-product-design">
      <div className="container">
        <div className="text-center" style={{ margin: "60px 0" }}>
          <h1 className="mb-4">
            Craft <b>delightful UI/UX</b> for <br /> your digital products
          </h1>
          <p style={{ color: "#272727" }}>Designed for remote cooperations</p>
        </div>
        <div className="d-flex justify-content-center">
          <img
            src="/Asset/Services/Digital Product Design/Header.jpg"
            alt="Digital Product Design"
            style={{ width: "100%", backgroundSize: "cover" }}
          />
        </div>
        <div
          className="d-flex jus justify-content-center"
          style={{ margin: "60px 0" }}
        >
          <Header
            styles="text-center threeperfour-width"
            header2="In the middle, there are following steps"
            header1="Product Design stages"
            text="A 5 Stage Process to Better Product Design. At its core, design thinking is a problem-solving methodology that, first and foremost, focuses on the user’s needs rather than the product’s specifications. Teams that fuse design thinking into their design process can develop better, user-centered products"
          />
        </div>
        <div className="text-center text-md-start">
          <div className="row " style={{ margin: "0 40px" }}>
            <div className="col-md-7 order-md-first order-first">
              <h4 style={{ background: "url('../../../Asset/01.png')" }}>
                Empathise
              </h4>
              <h5>Critical starting point</h5>
              <p>
                The first stage of the process is spent getting to know the user
                and understanding their wants, needs and objectives. This means
                observing and engaging with people in order to understand them
                on a psychological and emotional level.
              </p>
              <p>
                During this phase, the designer seeks to set aside their
                assumptions and gather real insights about the user. Learn all
                about key empathy-building methods here.
              </p>
            </div>
            <div className="col-md-4 order-md-first order-first ">
              <div className="row dpd-list">
                <div className="dpm-icon col-md-6">
                  <img
                    src="/Asset/Services/Digital Product Design/User interviews.svg"
                    alt="img-icon"
                  />
                  <h6>User interview</h6>
                </div>
                <div className="dpm-icon col-md-6">
                  <img
                    src="/Asset/Services/Digital Product Design/Empathy customer journey maps.svg"
                    alt="img-icon"
                  />
                  <h6>Empathy & customer journey maps</h6>
                </div>
                <div className="dpm-icon col-md-6">
                  <img
                    src="/Asset/Services/Digital Product Design/Group 64.svg"
                    alt="img-icon"
                  />
                  <h6>User interview</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ margin: "0 40px" }}>
            <div className="col-md-4  order-md-first order-last ">
              <div className="row dpd-list">
                <div className="dpm-icon col-6 ">
                  <img
                    src="/Asset/Services/Digital Product Design/User interviews.svg"
                    alt="img-icon"
                  />
                  <h6>User interview</h6>
                </div>
                <div className=" col-6 "></div>
                <div className=" col-6 "></div>
                <div className="dpm-icon col-6">
                  <img
                    src="/Asset/Services/Digital Product Design/Empathy customer journey maps.svg"
                    alt="img-icon"
                  />
                  <h6>Empathy & customer journey maps</h6>
                </div>
              </div>
            </div>

            <div className="col-md-7  order-md-last order-first ">
              <h4>Define</h4>
              <h5>Defining the problem</h5>
              <p>
                The second stage in the Design Thinking process is dedicated to
                defining the problem. You’ll gather all of your findings from
                the empathise phase and start to make sense of them: what
                difficulties and barriers are your users coming up against?
              </p>
              <p>
                What patterns do you observe? What is the big user problem that
                your team needs to solve? By the end of the define phase, you
                will have a clear problem statement. The key here is to frame
                the problem in a user-centered way; rather than saying “We need
                to…”, frame it in terms of your user: “Retirees in the Bay area
                need…”
              </p>
              <p>
                Once you’ve formulated the problem into words, you can start to
                come up with solutions and ideas — which brings us onto stage
                three.
              </p>
            </div>
          </div>

          <div className="row" style={{ margin: "0 40px" }}>
            <div className="col-md-7 order-md-last order-last">
              <h4>Ideate</h4>
              <h5>Solid understanding</h5>
              <p>
                it’s time to start working on potential solutions. The third
                phase in the Design Thinking process is where the creativity
                happens, and it’s crucial to point out that the ideation stage
                is a judgement-free zone! Designers will hold ideation sessions
                in order to come up with as many new angles and ideas as
                possible.
              </p>
              <p>
                There are many different types of ideation technique that
                designers might use, from brainstorming and mindmapping to
                bodystorming (roleplay scenarios) and provocation — an extreme
                lateral-thinking technique that gets the designer to challenge
                established beliefs and explore new options and alternatives.
              </p>
              <p>
                Towards the end of the ideation phase, you’ll narrow it down to
                a few ideas with which to move forward. You can learn about all
                the most important ideation techniques here.
              </p>
            </div>

            <div className="col-md-4 order-md-last order-last ">
              <div className="row dpd-list">
                <div className="dpm-icon col-6">
                  <img
                    src="/Asset/Services/Digital Product Design/Moodboarding.svg"
                    alt="img-icon"
                  />
                  <h6>Moodboarding</h6>
                </div>
                <div className="col-6"></div>
                <div className="dpm-icon col-6">
                  <img
                    src="/Asset/Services/Digital Product Design/Group 80.svg"
                    alt="img-icon"
                  />
                  <h6>Wireframing</h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ margin: "0 40px" }}>
            <div className="col-md-4 order-md-first order-last ">
              <div className="row dpd-list">
                <div className="dpm-icon col-6 ">
                  <img
                    src="/Asset/Services/Digital Product Design/Group 70.svg"
                    alt="img-icon"
                  />
                  <h6>User interview</h6>
                </div>
                <div className=" col-6 "></div>
              </div>
            </div>

            <div className="col-md-7 order-md-last order-first">
              <h4>Prototype</h4>
              <h5>Experimentation</h5>
              <p>
                A prototype is basically a scaled-down version of the product
                which incorporates the potential solutions identified in the
                previous stages. This step is key in putting each solution to
                the test and highlighting any constraints and flaws.
              </p>
              <p>
                Throughout the prototype stage, the proposed solutions may be
                accepted, improved, redesigned or rejected depending on how they
                fare in prototype form. You can read all about the prototyping
                stage of Design Thinking in this in-depth guide.
              </p>
            </div>
          </div>

          <div className="row" style={{ margin: "0 40px" }}>
            <div className="col-md-7 order-md-first order-first">
              <h4>Test</h4>
              <h5>Build test repeat</h5>
              <p>
                After prototyping comes user testing, but it’s important to note
                that this is rarely the end of the Design Thinking process. In
                reality, the results of the testing phase will often lead you
                back to a previous step, providing the insights you need to
                redefine the original problem statement or to come up with new
                ideas you hadn’t thought of before. Learn all about user testing
                in this guide.
              </p>
            </div>

            <div className="col-md-4 order-md-last order-last ">
              <div className="row dpd-list">
                <div className="dpm-icon col-6 ">
                  <img
                    src="/Asset/Services/Digital Product Design/Group.svg"
                    alt="img-icon"
                  />
                  <h6>User interview</h6>
                </div>
                <div className=" col-6 "></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service_DigitalProductDesign;
