const Cards = [
  {
    id: 1,
    title: "Complete product teams",
    text: "Access Business Analysts, Product Designers, Project Managers, QA Engineers, DevOps, or Full-stack developers",
    imgUrl: "/Asset/Services/Website development/Complete product teams.svg",
  },
  {
    id: 2,
    title: "Fast iterations & frequent releases",
    text: "Pre-build components and our own templates let our team deliver more in each sprint – this is the perk of having such experienced web developers on board",
    imgUrl:
      "/Asset/Services/Website development/Fast iterations _ frequent releases.svg",
  },
  {
    id: 3,
    title: "Business-tailored architecture",
    text: "Always designed and develop with your company's growth and the product's scalability in mind",
    imgUrl:
      "/Asset/Services/Website development/Business-tailored architecture.svg",
  },
  {
    id: 4,
    title: "Thorough quality assurance",
    text: "We do QA before each deploy through manual and automated tests that catch bugs, performance issues, and conversion blockers",
    imgUrl:
      "/Asset/Services/Website development/Thorough quality assurance.svg",
  },
  {
    id: 5,
    title: "Scalable & secure infrastructure",
    text: "Web development services full of custom solutions optimized for efficiency, flexibility and deployment speed",
    imgUrl:
      "/Asset/Services/Website development/Scalable _ secure infrastructure.svg",
  },
  {
    id: 6,
    title: "Complete product teams",
    text: "Designed to help your business with user adoption",
    imgUrl: "/Asset/Services/Website development/World-class UIUX.svg",
  },
];
export default Cards;
