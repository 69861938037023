const icons = [
  {
    id: 1,
    imgUrl: "1.png",
  },
  {
    id: 2,
    imgUrl: "2.png",
  },
  {
    id: 3,
    imgUrl: "3.png",
  },
  {
    id: 4,
    imgUrl: "4.png",
  },
  {
    id: 5,
    imgUrl: "5.png",
  },
  {
    id: 6,
    imgUrl: "6.png",
  },
  {
    id: 7,
    imgUrl: "7.png",
  },
  {
    id: 8,
    imgUrl: "8.png",
  },
  {
    id: 9,
    imgUrl: "9.png",
  },
  {
    id: 10,
    imgUrl: "10.png",
  },
  {
    id: 11,
    imgUrl: "11.png",
  },
  {
    id: 12,
    imgUrl: "12.png",
  },
  {
    id: 13,
    imgUrl: "13.png",
  },
  {
    id: 14,
    imgUrl: "14.png",
  },
  
];

export default icons;