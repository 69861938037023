import React from "react";

const ServiceCarouselContent = (props) => {
  const lists = props.lists;
  return (
    <div
      className="row service-caroiusel-content"
      id="service-caroiusel-content"
    >
      <div className="col-md-6">
        <p>{props.text1}</p>
        <p>{props.text2}</p>
      </div>
      <div className="col-md-6">
        <div className="content-list">
          <div>
            <h3>Your new team will</h3>
            {lists && (
              <ul>
                {lists.map((list) => {
                  return (
                    <li key={list.id}>
                      <img
                        src="Asset/Services/Website development/check.svg"
                        alt="check"
                      />
                      {list.list}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCarouselContent;
