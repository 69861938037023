import React from "react";
import Header from "../../components/Header/Header";
import "./VisiMisi.css";

const VisiMisi = () => {
  return (
    <div id="visimisi">
      <div className="container-md">
        <Header
          styles="m-header"
          header2="Making a different"
          header1="Our story"
          text=""
        />
        <div className="row visimisi-content" style={{ marginBottom: "90px" }}>
          <div className="col-md-6">
            <p>
              PT. Alba Digital Teknologi is a digital agency that has the
              tagline "Provide The Best Digital Experience for Your Business".
              We have to provide the best digital experience for our clients.
            </p>
            <p>
              Focus on creating websites and mobile applications is part of
              ALBA's services and has segmentation in digital communication
              marketing. Alba has helped several large companies to carry out
              business development and digital transformation.
            </p>
            <h3>Our Vision</h3>
            <p>
              Become leading Digital Marketing Agency in Indonesia and provide a
              result-driven strategy to help business growth with Digital
              Transformation.
            </p>
            <h3>Our Mission</h3>
            <p>
              "Provide The Best Digital Experience for Your Business" becomes
              our concern to grow your business. We believe in delivering
              positive results to grow your brand will improve your customer
              journey and digital visibility.
            </p>
          </div>
          <div className="col-md-6">
            <img
              src="/Asset/aboutUs/Our Story(2).jpg"
              alt="our-story"
              className="content-img"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisiMisi;
