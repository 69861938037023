import React from "react";
import "./ServiceHero.css";

const ServiceHero = (props) => {
  const icons = props.icons;
  return (
    <div
      className="service-hero d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${props.imgBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="service-hero-wrapper text-center ">
        <h4>{props.header1}</h4>
        <h1>{props.header2}</h1>
        <p>{props.text}</p>
        {icons && (
          <div>
            {icons.map((icon) => {
              return (
                <img
                  src={`Asset/Services/Mobile apps/${icon.imgUrl}`}
                  alt="React"
                  key={icon.id}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceHero;
