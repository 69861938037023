import React from "react";
import Header from "../../../components/Header/Header";
import ServiceHero from "../../../components/ServiceComponents/Service-Hero/ServiceHero";
import "./Maintenance.css";

function Maintenance() {
  const maintenanceData = [
    {
      id: 1,
      title: "Business analysis",
      imgUrl: "/Asset/Services/Digital Maintenance/Business analysis.svg",
      lists: [
        { id: 1, text: "Scope of work determination" },
        { id: 2, text: "Progress measurement" },
        { id: 3, text: "Backlog management" },
        { id: 4, text: "DoR, DoD standards" },
        { id: 5, text: "Proxy-PO activities" },
      ],
    },
    {
      id: 2,
      title: "Manual testing",
      imgUrl: "/Asset/Services/Digital Maintenance/Manual testing.svg",
      lists: [
        { id: 1, text: "Exploratory and regression tests" },
        { id: 2, text: "Test case documentation" },
        { id: 3, text: "Non-functional tests" },
        { id: 4, text: "Test Execution Reports" },
        { id: 5, text: "Application log monitoring" },
      ],
    },
    {
      id: 3,
      title: "Test automation",
      imgUrl: "/Asset/Services/Digital Maintenance/Tes automation.svg",
      lists: [
        { id: 1, text: "ROI Analysis" },
        { id: 2, text: "Framework recommendations" },
        { id: 3, text: "API and Visual UI testing" },
        { id: 4, text: "Test Execution Reports" },
        { id: 5, text: "E2E testing aligned with CI/CD process" },
      ],
    },
    {
      id: 4,
      title: "Performance testing",
      imgUrl: "/Asset/Services/Digital Maintenance/Performance testing.svg",
      lists: [
        { id: 1, text: "Tools recommendation" },
        { id: 2, text: "Full performance tests" },
        { id: 3, text: "Load/stress testing" },
        { id: 4, text: "Monitoring application’s health" },
        { id: 5, text: "Performance and scalability testing" },
      ],
    },
    {
      id: 5,
      title: "Security testing",
      imgUrl: "/Asset/Services/Digital Maintenance/Security testing.svg",
      lists: [
        { id: 1, text: "Reconnaissance" },
        { id: 2, text: "Risk analysis and assessment" },
        { id: 3, text: "Vulnerability overview" },
        { id: 4, text: "GDPR penetration testing" },
        { id: 5, text: "OWASP TOP 10, PTES, OSSTMM" },
      ],
    },
  ];

  return (
    <section id="maintenance">
      <div className="container maintenance">
        <ServiceHero
          header2={"Digital Maintenance as a Service"}
          text={"Ensure your digital products stay at their best"}
          imgBackground={
            "../../../../public/Asset/Services/Digital Maintenance/Header.jpg"
          }
        />
        <Header
          styles="m-header text-center"
          header2="The complete package"
          header1="Behind the repair of your product"
        />
        <div className="row">
          {maintenanceData.map((data) => {
            return (
              <div className="col-md-4 d-flex" key={data.id}>
                <div className="me-2">
                  <img src={data.imgUrl} alt="maintenance" />
                </div>
                <div>
                  <h3>{data.title}</h3>
                  <ul>
                    {data.lists.map((list) => {
                      return <li key={list.id}>{list.text}</li>;
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Maintenance;
