const portfolioData = [
  {
    id: 1,
    imgUrl: "/Asset/Home/Blog/JagoApps.jpg",
    titleType: "Mobile apps",
    titleName: "ILIOS app (B2B E-commerce)",
    desc: "Has a unique selling point where not all companies offer applications for sales, data collection and management.",
    link: "ilios",
  },
  {
    id: 2,
    imgUrl: "/Asset/Home/Blog/WebDev.jpg",
    titleType: "Website development",
    titleName: "Home and Living",
    desc: "Albatech helps in creating a website catalog to show brand identity and information in accordance with vision and mission of Home and Living.",
    link: "home_and_living",
  },
  {
    id: 3,
    imgUrl: "/Asset/Home/Blog/WebDev2.jpg",
    titleType: "Website development",
    titleName: "Hippo",
    desc: "Albatech help to create e-commerce and company profile for Hippo’s Website with realtime transaction and integration with Payment Gateway to accept multi payment.",
    link: "hippo",
  },
];

export default portfolioData;
